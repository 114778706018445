import React, {useEffect, useState, useContext} from "react";
import "../../styles/GeneratedInvoice.css";
import userContext from "../../context/UserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "./Spinner";
function ClientGeneratedInvoice() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const context = useContext(userContext);
  const {user, getUser, getBankInfoData, userBankingData, profileName, changeInvoiceBtnTxt, setChangeInvoiceBtnTxt, userProfile, getUserProfileData} = context;
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [interest, setInterest] = useState(null)
  const [loading, setLoading] = useState(true)
  const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [skickaLoading, setSkickaLoading] = useState(false);
  const [sentStatus, setSentStatus] = useState(false);
  const [amountLimit, setAmountLimit] = useState(0);
  const [firstInvoice, setFirstInvoice] = useState(false);
  const [radioClicked, setRadioClicked] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const apiUrl = 'https://globalworker-backend.vercel.app';
  useEffect(() => {
    
    // setLoading(true)
    document.title = 'Freelancer - Regular Invoice'
    const getInvoiceData = async () => {
      
      try {
        const response = await fetch(
          `${apiUrl}/api/invoice/invoicedata/${invoiceId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)
        if(data.client.clientOption === 'private'){
          if(data.client.privateClient.pvtCurrencyCode === 'EUR'){
            setAmountLimit(200);
          }
          else if(data.client.privateClient.pvtCurrencyCode === 'SEK'){
            setAmountLimit(2000)
          }
        }
        else if(data.client.clientOption === 'organization'){
          if(data.client.organizationClient.orgCurrencyCode === 'EUR'){
            setAmountLimit(200);
          }
          else if(data?.client?.organizationClient?.orgCurrencyCode === 'SEK'){
            setAmountLimit(2000)
          }
        }
        console.log(amountLimit);
        setInvoiceData(data);

        const sentInvRes = await fetch(
          `${apiUrl}/api/invoice/getsentInvoicesofmember/${data.invoice.user}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          }
        );
        if (!sentInvRes.ok) {
          throw new Error("Network response was not ok");
        }
        const sentInvData = await sentInvRes.json();
        console.log(sentInvData);
        setFirstInvoice(sentInvData);
        setTimeout(()=>{
          console.log(userProfile)
          setLoading(false);
        }, 3000)
        setSentStatus(data.invoice.sentStatus);
      } catch (error) {
        console.error("Error while getting Client info:", error);
      }
    };
    getUserProfileData();
    getUser();
    getInvoiceData();
    getBankInfoData();
  }, []);

  useEffect(() => {
    const calculateInterestAndTotalAmount = () => {
      if (userBankingData) {
        if (userBankingData.bankAndWiseOption === 'bankAccountInfo') {
          setInterest(8);
          let amount = parseFloat(invoiceData?.invoice?.totalAmount) * 1.08;
          setTotalAmountWithInterest(amount);
        } else if (userBankingData.bankAndWiseOption === 'wiseAccountInfo') {
          setInterest(6);
          let amount = parseFloat(invoiceData?.invoice?.totalAmount) * 1.06;
          setTotalAmountWithInterest(amount);
        }
      }
    };
  
    calculateInterestAndTotalAmount();
  }, [userBankingData, invoiceData]);

  const invoiceSendToAdmin = async () =>{
    setDisabledBtn(true)
    setSkickaLoading(true);
    let errorMessage = {};
    try {
      console.log(invoiceData);
      const response = await fetch(`${apiUrl}/api/invoice/sentInvoices`,{
        method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify({invoice: invoiceData.invoice, invoiceAmount: invoiceData.invoiceAmount}),
      })
      if (!response.ok) {
        setDisabledBtn(false)
        setSkickaLoading(false);
        errorMessage = await response.json();
        console.log(errorMessage)
        console.log(errorMessage.message)
        throw new Error("Network response was not ok");
      }

      const newData = {
        invoice: invoiceData.invoice,
        clientData: invoiceData.client,
        invoiceAmount: invoiceData.invoiceAmount,
        userData: userProfile
      }
      //generating pdf email to admin
      const pdfResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/generateinvoicepdfnew`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
      },
        body: JSON.stringify({invoiceData: newData, totalAmountWithInterest: invoiceData.invoice.totalAmount, interest: 8}),
      });
      if (!pdfResponse.ok) {
        setDisabledBtn(false)
        setLoading(false);
        throw new Error("error while generating pdf");
      }

      const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/sendinvoicepdftoadmin`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify({userData: userProfile, invoice: invoiceData.invoice})
          });
      
          if (!sendEmailResponse.ok) {
            setDisabledBtn(false)
            setLoading(false);
            throw new Error('Error while sending email with PDF attachment.');
          }

      setSkickaLoading(false);
      setDisabledBtn(false)
      appendAlert("Invoice sent Successfully.", "success");
      console.log("Successfully sent")
      setSentStatus(true);
    } catch (error) {
      setDisabledBtn(false)
      setSkickaLoading(false);
      appendAlert(errorMessage.message, "danger");
      setTimeout(()=>{
        if(errorMessage.message === 'user profile does not exists. please setup your profile'){
          setSkickaLoading(false);
          navigate('/freelancer-user-profile')
        } else if(errorMessage.message === 'Bank information does not exists. please setup your bank information'){
          setSkickaLoading(false);
          navigate('/freelancer-bank-information')
        }
      }, 3000)
      console.log("Failed")
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  const handleSubmitWithAgreement = async (e) =>{
    e.preventDefault();
    console.log("submitted"); 
    await invoiceSendToAdmin(e);
    setFirstInvoice(true);
  }

  const handleAgreementChecked = () =>{
    setAgreementChecked(true);
  }

  const radioHandle = (e) =>{
    setRadioClicked(e.target.value)
    console.log(e.target.value)
  }
  return (
    <>
    {loading && <Spinner/>}
    {!loading && <>
    <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
    <div className="mt-3 buttonMainContainer">
      <div className="button-div">
        <button className="previewInvoiceButtons goBackBtn" onClick={()=>{navigate(-1); setChangeInvoiceBtnTxt('previewInvoice')}}>Gå tillbaka</button>
        {/* { invoiceData?.invoice?.totalAmount < amountLimit ? <button type="button" className="previewInvoiceButtons sendInvoiceBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={()=>{setDisabledBtn(true)}}> */}
        {/* {sentStatus ? 'Fakturan är Skickad' : 'Skicka Faktura'}</button> :  */}
        {!firstInvoice ? <button disabled={disabledBtn} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="previewInvoiceButtons sendInvoiceBtn" >Skicka Faktura</button> : <button disabled={sentStatus ? true : false} className="previewInvoiceButtons sendInvoiceBtn" onClick={invoiceSendToAdmin}>{sentStatus ? 'Fakturan är Skickad' : 'Skicka Faktura'}</button>}
        <button className="previewInvoiceButtons editInvoiceBtn" onClick={()=>{navigate(`/freelancer-edit-invoice/${invoiceId}`)}}>Redigera</button>
        </div>
        </div> 
        {skickaLoading && <Spinner/>}
      <div className="invoiceBodyContainer">
        <div className="generatedInvoiceContainer">
      <div className="mt-3 mb-2" style={{width: '80%', display: 'flex', justifyContent: 'end'}}>
        <table>
          <tbody>
          <tr>
            <th>Invoice No.</th>
            <td>{invoiceData?.invoice?.invoiceNumber}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="g-invoiceHeader mb-2">
        <img src={require("../../assets/hela@10x-100.png")} alt="noimgae" />
        <table className="table table-bordered g-invoiceHeader-table">
        <tbody>
          <tr>
            <th className="g-invoiceHeader-col">Date</th>
            <th className="g-invoiceHeader-col">Customer</th>
          </tr>
          <tr>
            <td className="g-invoiceHeader-col">{currentDate.toLocaleDateString()}</td>
            <td className="g-invoiceHeader-col">{invoiceData?.client?.customerNumber}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="invoiceCustomerDetails mb-2 mt-2">
      <table className="invoiceCustomerDetailsTable" >
          <tbody>
          <tr>
            <th>Our Reference:</th>
            <td>{invoiceData?.invoice?.clientName}</td>
          </tr>
          <tr>
            <td>{profileName !== "" ? profileName : user.name}</td>
            <td>{invoiceData?.client?.clientOption === "private"
                ? invoiceData?.client?.privateClient?.pvtStreet
                : invoiceData?.client?.organizationClient?.orgStreet}
            </td>
          </tr>
          <tr>
            <td>{user.email}</td>
            <td>{`${invoiceData?.client?.clientOption === "private"
                  ? invoiceData?.client?.privateClient?.pvtPostalCode
                  : invoiceData?.client?.organizationClient?.orgPostalCode} 
                  ${invoiceData?.client?.clientOption === "private"
                  ? invoiceData?.client?.privateClient?.pvtCity
                  : invoiceData?.client?.organizationClient?.orgCity}`}</td>
          </tr>
          <tr>
            <th>Your Reference:</th>
            <td>{invoiceData?.client?.clientOption === "private"
                  ? invoiceData?.client?.privateClient?.pvtCountry
                  : invoiceData?.client?.organizationClient?.orgCountry}
            </td>
          </tr>
          <tr>
            <td>{invoiceData?.client?.clientOption === "private"
                  ? invoiceData?.client?.privateClient?.pvtReferenceName
                  : invoiceData?.client?.organizationClient?.orgReferenceName}
            </td>
            <td></td>
          </tr>
          <tr>
            <td><b>Customer Email:</b> {invoiceData?.client?.clientOption === "private"
                  ? invoiceData?.client?.privateClient?.pvtRecipientEmail
                  : invoiceData?.client?.organizationClient?.orgRecipientEmail}</td>
            <td>
            </td>
          </tr>
          <tr>
            <td><b>Payment terms:</b> {invoiceData?.invoice?.noOfDueDates}</td>
            <td></td>
          </tr>
          <tr>
            <td><b>Due Date:</b> {invoiceData?.invoice?.dueDate}</td>
            <td></td>
          </tr>
          <tr>
            <td><b>Interest:</b> 8%</td>
            <td></td>
          </tr>
          </tbody>
        </table>
        
      </div>
      <div className="invoiceAmountContainer mb-3">
        <table className="invoiceAmountTable table table-bordered">
          <tbody>
          <tr className="amountDataRow">
            <th className="data-1" colSpan="3">Work Description</th>
            <th className="data-12">Quantity</th>
            <th className="data-12">Unit</th>
            <th className="data-12">Price</th>
            <th className="data-14">Amount</th>
          </tr>
            {invoiceData?.invoiceAmount?.map((data, index) => (
              <tr key={index}>
                <td colSpan="3">{data.workDescription}</td>
                <td >{data.quantity}</td>
                <td >{data.unit}</td>
                <td >{data.fixedPrice == null ? data.price : data.fixedPrice}</td>
                <td >{data.totalAmount}</td>
              </tr>
            ))}
          <tr className="totalAmount-row">
            <th colSpan="5"></th>
            <th className="totalAmountData" >To pay</th>
            <td className="totalAmountData">{invoiceData?.invoice?.totalAmount} {invoiceData?.client?.privateClient?.pvtCurrencyCode ||invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="invoice-note-container">
        <p>
          <b>Note: Please add the invoice number: {invoiceData?.invoice?.invoiceNumber} in the reference section
          of your online payment
          </b></p>
        <p className=""><b>We only accept EUR</b></p>
        <p className="mt-3"><b>Message:</b> {invoiceData?.invoice?.note}</p>
      </div>
      <div className="row invoice-bankInfo-container">
        <div className="col invoice-bankInfo-col-1">
          <h5>URL</h5>
          <Link className="globalWorkerUrl" to={'https://globalworker.se'}>https://globalworker.se</Link>
          <h5 className="mt-4">Mail</h5>
          <p>info@globalworker.se</p>
        </div>
        <div className="col">
          <table className="invoice-bankInfo-table">
            <tbody>
              <tr>
                {/* <th>BIC/SWIFT CODE</th> */}
                <td><b>BIC/SWIFT CODE:</b>  TRWIBEB1XXX</td>
              </tr>
              <tr>
                {/* <th>IBAN</th> */}
                <td><b>IBAN:</b>  BE09 9675 1244 0757</td>
              </tr>
              <tr>
                {/* <th>Bank Name</th> */}
                <td><b>Bank Name:</b>  Wise Europe SA</td>
              </tr>
              <tr>
                {/* <th>City</th> */}
                <td><b>City:</b>  BRUSSELS</td>
              </tr>
              <tr>
                {/* <th>Country</th> */}
                <td><b>Country:</b>  BELGIUM</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer className="address-footer">
        <p>GLOBAL FREELANCER WORKERS LTD</p>
        <p>STRON LEGAL CLUBHOUSE ST JAMES 8</p>
        <p>SW1Y 4JU, United Kingdom, Company No. 14534594</p>
        <p className="mt-3">&copy; 2024-2025 Globalworker. All rights reserved.</p>
      </footer>
    </div>
    </div>

    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel"><strong>Avtal!</strong></h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Klicka på länken nedan och läs avtalet</p>
              <Link onClick={()=>{handleAgreementChecked()}} to='https://globalworker.se/avtal/globalworker-medlemmar-av-org.pdf' target="_blank">https://globalworker.se/avtal/globalworker-medlemmar-av-org.pdf</Link>
            </div>
            <form className="modal-footer" onSubmit={handleSubmitWithAgreement} style={{justifyContent: 'center'}}>
              <div style={{width: '100%', display: 'flex'}}>
                <div className="form-check modal-footer-form">
                  <input className="form-check-input" onChange={radioHandle} value={true} type="radio" name="agreementRadio" id="agreementRadio" style={{border: '2px solid #b7a9a9', margin: '0px'}} required disabled={!agreementChecked}/>
                  <label className="form-check-label" htmlFor="agreementRadio">
                    Jag har läst hela avtalet
                  </label>
                </div>
              </div>
              <div className="">
                <button type="submit" data-bs-dismiss={radioClicked && "modal"} aria-label={radioClicked && "Close"} className="invoice-btn-grp" id="inv-first-btn" disabled={!radioClicked}>
                Skicka Faktura
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    {/*Modal   */}
    {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel"><strong>Varning!</strong></h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <p>OPPS, du är på väg att skicka en faktura som är under €200 alternativt 2000SEK! Enligt vårt avtal som du har godkänt så kommer vi att lägga på en avgift på €25 alternativt 250SEK på den ordinarie avgiften.</p>
        <p>Om du vill vänta med att skicka fakturan tills du har en större summa att fakturera för att undvika den högre avgiften så klickar du på "Gå Tillbaka" eller "Redigera", annars klickar du på "Skicka Fakturan" och accepterar den högre avgiften.</p>
      </div>
      <div className="modal-footer" style={{justifyContent: 'center'}}>
        <div className="">
          <button className="goBackBtn btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{navigate(-1); setChangeInvoiceBtnTxt(true)}}>Gå tillbaka</button>
        </div>
        <div className="">
          <button className="btn btn-primary editInvoiceBtn" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{navigate(`/freelancer-edit-invoice/${invoiceId}`)}}>Redigera</button>
        </div>
        <div >
          <button disabled={disabledBtn} className="sendInvoiceBtn btn btn-primary" onClick={invoiceSendToAdmin} data-bs-dismiss="modal" aria-label="Close">{sentStatus ? 'Fakturan är Skickad' : 'Skicka Faktura'}</button>
        </div>
      </div>
    </div>
  </div>
</div> */}

    </>}
    </>
  );
}

export default ClientGeneratedInvoice;
