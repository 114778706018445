import React, { useState, useEffect, useContext } from "react";
import Layout from "../../common/Layout";
import "../../styles/FreelancerUserProfile.css";
import "../../styles/FreelancerBankInfo.css";
import FreelancerWiseAccountForm from "./FreelancerWiseAccountForm";
import FreelancerBankAccountForm from "./FreelancerBankAccountForm";
import FreelancerTaxForm from "./FreelancerTaxForm";
import userContext from "../../context/UserContext";
import Spinner from "./Spinner";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PaypalInfo from "./PaypalInfo";

function FreelancerBankInfo() {
  const navigate = useNavigate();
  const context = useContext(userContext);
  const { saveUserBankingInfo, bankInfoModal, setBankInfoModal, setDashboardModal } = context;
  const selectedWiseCountry = 'Belgium'; 
  const wiseBankNameInput = `Wise Europe SA`
  const [selectedBankOption, setSelectedBankOption] = useState('');
  const [selectedTaxOption, setSelectedTaxOption] = useState('');
  const [optionHeading, setOptionHeading] = useState('');
  const [loading, setLoading] = useState(true);
  const [tempBankData, setTempBankData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [readMoreOption, setReadMoreOption] = useState({
    option: 0,
    amount: 0,
    firstFee: 0,
    secondFee: 0
  });

  const [wiseInputValues, setWiseInputValues] = useState({
    country: selectedWiseCountry,
    wiseBankName: wiseBankNameInput,
    ibanNumber: ''
  });
  
  const [bankInputValues, setBankInputValues] = useState({
    country: "",
    bankName: "",
    accountNumber: "",
    ibanNumber: "",
    swiftCode: "",
    otherInfo: ""
  });

  const [paypalEmail, setPaypalEmail] = useState("");

  const [wePayYourTaxInputValues, setWePayYourTaxInputValues] = useState({
    country: "",
    taxPercentage: "",
    taxNumber: "",
    otherTaxInfo: ""
  });
  const apiUrl = 'https://globalworker-backend.vercel.app';
  useEffect(() => {
    const getBankInfoData = async () =>{
      try {
        const response = await fetch(`${apiUrl}/api/bankinformation/getbankinfo`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const bankInfoData = await response.json();
        if(bankInfoData){
          setTempBankData(bankInfoData);
          setSelectedBankOption(bankInfoData.bankAndWiseOption);
          setSelectedTaxOption(bankInfoData.taxPaymentOption);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error while getting bank info:", error);
      }
    }
      getBankInfoData()
      console.log(bankInfoModal);
  }, []);
  
  
  const bankOnChangeHandle = (e) =>{
      setSelectedBankOption(e.target.value);
  }
  const taxOnChangeHandle = (e) =>{
    setSelectedTaxOption(e.target.value);
}
const handleSaveAndDashboard = async (e) =>{
  e.preventDefault();
  await handleSave(e);
  navigate('/');
  setDashboardModal(true);
}

const handleSave = async (e) => {
  setSubmitLoading(true)
  e.preventDefault()
  try {
    console.log(bankInputValues)
    console.log(wiseInputValues)
    console.log(selectedBankOption)
    let response = false;
    if (selectedBankOption === "wiseAccountInfo") {
      response = await saveUserBankingInfo({bankAndWiseOption: selectedBankOption, wiseAccountInfo: wiseInputValues});
    } else if (selectedBankOption === "bankAccountInfo") {
      response = await saveUserBankingInfo({bankAndWiseOption: selectedBankOption, bankAccountInfo: bankInputValues});
      console.log("Saved");
    } else if (selectedBankOption === "paypalInfo") {
      response = await saveUserBankingInfo({bankAndWiseOption: selectedBankOption, paypalInfo: {email: paypalEmail}});
      console.log("Saved");
    }
    if (selectedTaxOption === "youPayYourTax") {
      response = await saveUserBankingInfo({ taxPaymentOption: selectedTaxOption });
    } else if (selectedTaxOption === "wePayYourTax") {
      response = await saveUserBankingInfo({taxPaymentOption: selectedTaxOption, wePayYourTax: wePayYourTaxInputValues,
      });
    }
    if(response){
      setSubmitLoading(false)
      appendAlert("Information saved successfully!!", "success");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      console.log("Information Saved Successfully")
    } else{
      setSubmitLoading(false)
      console.error("Error saving Data:");
      appendAlert("Error occurred. Please try again.", "danger");
    }
  } catch (error) {
    setSubmitLoading(false)
    console.error("Error saving Data:", error);
    appendAlert("Error occurred. Please try again.", "danger");
  }
};

const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
const appendAlert = (message, type) => {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = [
    `<div class="alert alert-${type} alert-dismissible" role="alert">`,
    `   <div>${message}</div>`,
    '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
    '</div>'
  ].join('')

  alertPlaceholder.append(wrapper)
}
  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
    <div className="heading-div bank-info-heading-div" style={{width: '100%', marginLeft: '70px'}}>
      <h5 className="valuta-heading bank-info-heading">Betalningsinformation</h5>
    </div>
      
      {/* note: tax container is hidden for short time of period. i setted 100% width of left container which was 50% and setted left alignment. and right container was 50%. */}
    {loading ? <Spinner/> : <><form style={{width: '100%'}} onSubmit={tempBankData === null ? handleSaveAndDashboard : handleSave}>
    <div className="main-container-fl bank-info-main" >
      <div className="left-container-fl bank-info-left" style={{width: '50%', paddingLeft: '30px'}} >
            <h6 className="bank-info-secondary-txt">Jag väljer att fakturera enligt ikryssat alternativ.</h6>
        <div className="bank-info-radio-container">
          <div className="alternative1-container">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="wiseAccountInfo"
                id="alternativ1-input"
                value="wiseAccountInfo"
                checked={selectedBankOption === 'wiseAccountInfo'}
                onChange={bankOnChangeHandle}
              />
              <label className="form-check-label" htmlFor="alternativ1-input">
                    Alternativ 1 - Avgift 6%: Utbetalning till ett Wise konto.
              </label>
            </div>
                <div className='read-more-btn-div-bi'>
                <button onClick={()=>{setOptionHeading('Alternativ 1 - Avgift 6%: Utbetalning till ett Wise konto'); setReadMoreOption({option: 1, amount: 400, firstFee: 6, secondFee: 8})}} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className='read-more-btn-bi'>Läs mer</button>
                </div>
          </div>

          <div className="alternative1-container">
    
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="bankAccountInfo"
                id="alternativ2-input"
                value="bankAccountInfo"
                checked={selectedBankOption === 'bankAccountInfo'}
                onChange={bankOnChangeHandle}
              />
              <label className="form-check-label" htmlFor="alternativ2-input">
                    Alternativ 2 - Avgift 8%: Utbetalning till valfri bank.
              </label>
            </div>
                <div className='read-more-btn-div-bi'>
                    <button onClick={()=>{setOptionHeading('Alternativ 2 - Avgift 8%: Utbetalning till valfri bank'); setReadMoreOption({option: 2, amount: 500, firstFee: 8, secondFee: 10})}} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className='read-more-btn-bi'>Läs mer</button>
            </div>
          </div>

          <div className="alternative2-container">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="paypalInfo"
                id="alternativ3-input"
                value="paypalInfo"
                checked={selectedBankOption === 'paypalInfo'}
                onChange={bankOnChangeHandle}
              />
              <label className="form-check-label" htmlFor="alternativ3-input">
                    Alternativ 3 - Avgift 10%: Utbetalning till Paypal.
              </label>
            </div>
                <div className='read-more-btn-div-bi'>
                    <button onClick={()=>{setOptionHeading('Alternativ 3 - Avgift 10%: Utbetalning till ett PayPal - konto'); setReadMoreOption({option: 3, amount: 800, firstFee: 10, secondFee: 12})}} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className='read-more-btn-bi'>Läs mer</button>
            </div>
          </div>

          
        </div>
        {selectedBankOption === 'wiseAccountInfo' && <FreelancerWiseAccountForm wiseInputValues={wiseInputValues} setWiseInputValues={setWiseInputValues} selectedWiseCountry={selectedWiseCountry} wiseBankNameInput={wiseBankNameInput} handleSave= {handleSave}/>}
        {selectedBankOption === 'bankAccountInfo' && <FreelancerBankAccountForm bankInputValues={bankInputValues} setBankInputValues={setBankInputValues} />}
        {selectedBankOption === 'paypalInfo' && <PaypalInfo paypalEmail={paypalEmail} setPaypalEmail={setPaypalEmail} />}
        <div>
        </div>
      </div>
      {/* Display is none for right container */}
      <div className="right-container-bank-info bank-info-right" style={{width: '50%', paddingRight: '30px', display: 'none'}}>
      <Link to={'https://www.frilansgruppen.com/doc/euinfo.pdf'} target="_blank" style={{textAlign: 'left', display: 'block'}} className="mb-3 tax-info-secondary-txt">Informationslänkar om alla EU-länder angående Skatter, Myndigheter, mm.</Link>
      {/* <h6 className="tax-info-secondary-txt tax-info-secondary-txt2" >Skatteinbetalning: Vi hjälper dig att betala in din skatt mot en avgift på €50 oavsett land du betalar till.</h6> */}
        <div className="bank-info-radio-container">
          <div className="alternative1-container">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="youPayYourTax"
                id="you-pay-your-tax-input"
                value="youPayYourTax"
                checked={selectedTaxOption === 'youPayYourTax'}
                onChange={taxOnChangeHandle}
              />
              <label className="form-check-label" htmlFor="you-pay-your-tax-input">
                  Du betalar in din skatt
              </label>
            </div>
                
          </div>

          <div className="alternative2-container">
    
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="wePayYourTax"
                id="we-pay-your-tax-input"
                value="wePayYourTax"
                checked={selectedTaxOption === 'wePayYourTax'}
                onChange={taxOnChangeHandle}
              />
              <label className="form-check-label" htmlFor="we-pay-your-tax-input">
                    Vi betalar in din skatt
              </label>
            </div>
          </div>
        </div>
        <div>
        </div>
        {selectedTaxOption === 'wePayYourTax' && <FreelancerTaxForm wePayYourTaxInputValues={wePayYourTaxInputValues} setWePayYourTaxInputValues={setWePayYourTaxInputValues}/>}
      </div>
    </div>
      <div className="bank-save-btn-div">
        <button type="submit" className="bank-save-btn btn btn-primary">Spara</button>
      </div>
      <div style={{display: 'flex', marginLeft: '93px'}}>
        {submitLoading && <Spinner/>}
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{border: '5px solid #95B8D1'}}>
            <div className="modal-header">
              <h1 style={{color: '#95B8D1', fontWeight: 'bold', fontSize: '23px', fontFamily: 'serif'}} className="modal-title fs-5" id="exampleModalLabel">{optionHeading}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{textAlign: 'left', fontSize: 'medium'}}>
             <p style={{fontFamily: 'sans-serif'}}>Vår avgift är {readMoreOption.firstFee}% på fakturerat belopp, inga andra avgifter tillkommer. Lägsta summa du kan fakturera är €{readMoreOption.amount}. <br/><b>Vid lägre fakturasumma:</b><br/>Fakturerar du under €{readMoreOption.amount} då är vår avgift {readMoreOption.secondFee}% på fakturerat belopp.
             <br/><b>OBS:</b> Om du fakturerar under €200 så tillkommer en fast avgift på €25, att läggas till på den ordinarie avgiften.
             <br/><b>OBS:</b> Om du fakturerar under 2000SEK så tillkommer en fast avgift på 250SEK, att läggas till på den ordinarie avgiften.
             <br/>Om du markerar Alternativ {readMoreOption.option} så öppnar sig fält att fylla i</p>
            </div>
          </div>
        </div>
      </div>

      {bankInfoModal && (
        <div
          className="modal"
          tabIndex="-1"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Bankuppgifterna</h5>
              </div>
              <div className="modal-body">
                <p>Din profilinformation har sparats. Fyll nu i och spara bankuppgifterna.</p>
              </div>
              <div className="modal-footer">
              <button type="button" onClick={()=>{setBankInfoModal(false)}} className="btn btn-secondary" data-bs-dismiss="modal">Fortsätta</button>
              </div>
            </div>
          </div>
        </div>
      )}
      </form>

      </>
      
      }
  </Layout>
  )
}

export default FreelancerBankInfo
