import React, { useState } from "react";
import "../../styles/FreelancerCreateClient.css";
import { countries } from "countries-list";

function PrivateClientForm({ onChange, values, onChangePvtMobNo }) {
  const currencyCode = ["EUR", "SEK"];
  const allCountries = Object.values(countries);
  allCountries.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="pvtIdNumber" className="label form-label">
                Personnummer / ID: Nummer
              </label>
              <input
                type="text"
                value={values.pvtIdNumber}
                className="form-input form-control pvt-input-field"
                id="pvtIdNumber"
                onChange={onChange}
                required
              />
            </div>

            <div className="">
              <label htmlFor="pvtFirstName" className="label form-label">
                Förnamn
              </label>
              <input
                type="text"
                value={values.pvtFirstName}
                className="form-input form-control pvt-input-field"
                id="pvtFirstName"
                onChange={onChange}
                required
              />
            </div>
            <div className="">
              <label htmlFor="pvtLastName" className="label form-label">
                Efternamn
              </label>
              <input
                type="text"
                value={values.pvtLastName}
                className="form-input form-control pvt-input-field"
                id="pvtLastName"
                onChange={onChange}
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtRecipientEmail"
                className=" label form-label"
              >
                Fakturamottagare e-post (till den som har hand om betalningen av
                fakturan)
              </label>
              <input
                type="email"
                value={values.pvtRecipientEmail}
                className="form-input form-control pvt-input-field"
                id="pvtRecipientEmail"
                onChange={onChange}
                required
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtReferenceName"
                className=" label form-label"
              >
                Referensens namn (den person som har beställt, eller känner till
                beställningen av tjänsten)
              </label>
              <input
                type="text"
                value={values.pvtReferenceName}
                className="form-input form-control pvt-input-field pvt-input-field"
                id="pvtReferenceName"
                onChange={onChange}
                required
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtContactNumber"
                className=" label form-label"
              >
                Kontaktens / Beställarens mobil nummer (Måste även inkludera landskoden, t.ex.+46):
              </label>
              <input
                type="text"
                value={values.pvtContactNumber}
                className="form-input form-control pvt-input-field"
                id="pvtContactNumber"
                onChange={onChangePvtMobNo}
                required
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label className="label form-label">Valutakod</label>
              <select
              id="pvtCurrencyCode"
                className="form-select form-control wise-dropdown"
                onChange={onChange}
                required
                value={values.pvtCurrencyCode}
              >
                <option defaultValue="Markera Valuta" value="">
                  Markera Valuta
                </option>
                {currencyCode.map((currCode, index) => (
                  <option key={index} value={currCode}>
                    {currCode}
                  </option>
                ))}
              </select>
            </div>

            <h6 className="client-address-heading mt-4">
            Privatkund adress
            </h6>
            <div className="">
              <label htmlFor="pvtStreet" className="label form-label">
                Gata / Box
              </label>
              <input
                type="text"
                value={values.pvtStreet}
                className="form-input form-control pvt-input-field"
                id="pvtStreet"
                onChange={onChange}
                required
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtPostalCode"
                className=" label form-label"
              >
                Landskod / Postnummer (tex. SE-441 96)
              </label>
              <input
                type="text"
                value={values.pvtPostalCode}
                className="form-input form-control pvt-input-field"
                id="pvtPostalCode"
                onChange={onChange}
                required
              />
            </div>
            <div className="">
              <label htmlFor="pvtCity" className=" label form-label">
                Stad / Plats
              </label>
              <input
                type="text"
                value={values.pvtCity}
                className="form-input form-control pvt-input-field pvt-input-field"
                id="pvtCity"
                onChange={onChange}
                required
              />
            </div>
            <div className=" ">
              <label htmlFor="pvtCountry" className=" label form-label">
                Land
              </label>
              <select
                id="pvtCountry"
                className="form-select form-control wise-dropdown"
                onChange={onChange}
                value={values.pvtCountry}
                required
              >
                <option defaultValue="Markera Landet" value="">
                  Markera Landet
                </option>
                {allCountries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivateClientForm;
