import React, { useState, useEffect, useContext } from "react";
import Layout from "../../common/Layout";
import "../../styles/FreelancerInvoiceSection.css";
import FreelancerCreateInvoice from "./FreelancerCreateInvoice";
import userContext from "../../context/UserContext";
import { useParams, Link } from "react-router-dom";
import '../../styles/FreelancerInvoiceList.css';
import Spinner from "./Spinner";
function FreelancerEditInvoice() {
  const { invoiceId } = useParams();
  const context = useContext(userContext);
  const {updateInvoice, updateInvoiceAmount, deleteInvoiceAmount, userProfile} = context;
  const [numberOfDays, setNumberOfDays] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const [sentStatus, setSentStatus] = useState(false);
  const [selectedInvoiceAmountId, setSelectedInvoiceAmountId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState('');

  let optionselect = true;
  //Add Amount
  const initialInvoiceDetails = {
    invoiceId: invoiceId,
    unit: "timmar",
    quantity: "-",
    fixedPrice: null,
    price: "-",
    workDescription: "-",
    totalAmount: "-",
  };

  const [invoiceDetails, setInvoiceDetails] = useState(initialInvoiceDetails);
  const [selectedUnit, setSelectedUnit] = useState("Timmar");
  const [disabledFixedPriceField, setDisabledFixedPriceField] = useState(true);
  const [disabledQuantityAndPrice, setDisabledQuantityAndPrice] =
    useState(false);
  const [disabledBgColor, setDisabledBgColor] = useState(
    "form-control disabled-field"
  );
  const [qtyAndPriceBgClr, setQtyAndPriceBgClr] = useState("");
  const [hoursWorked, setHoursWorked] = useState("");
  const [hourlyPrice, setHourlyPrice] = useState("");
  const [fixedPriceValue, setFixedPriceValue] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [firstInvoice, setFirstInvoice] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const resetStates = () => {
    setInvoiceDetails(initialInvoiceDetails);
    setSelectedUnit("timmar");
    setDisabledFixedPriceField(true);
    setDisabledQuantityAndPrice(false);
    setDisabledBgColor("form-control disabled-field");
    setQtyAndPriceBgClr("");
    setHoursWorked("");
    setHourlyPrice("");
    setFixedPriceValue("");
    setTotalAmount(0);
    setTextAreaValue("");
  };
  const apiUrl = 'https://globalworker-backend.vercel.app';
  useEffect(() => {
    document.title = 'Freelancer - Edit Invoice'
    const getInvoiceData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/invoice/invoicedata/${invoiceId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)
        setInvoiceData(data);
        setSentStatus(data.invoice.sentStatus);
        setDueDate(data.invoice.dueDate);

        const sentInvRes = await fetch(
          `${apiUrl}/api/invoice/getsentInvoicesofmember/${data.invoice.user}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          }
        );
        if (!sentInvRes.ok) {
          throw new Error("Network response was not ok");
        }
        const sentInvData = await sentInvRes.json();
        console.log(sentInvData);
        setFirstInvoice(sentInvData);
        setTimeout(()=>{
          setLoading(false);
        }, 3000)
      } catch (error) {
        console.error("Error while getting Client info:", error);
      }
    };
    getInvoiceData();
  }, []);

  const handleNumberOfDaysChange = (e) => {
    const selectedDays = parseInt(e.target.value, 10);
    setNumberOfDays(selectedDays);
    if (!isNaN(selectedDays)) {
      const currentDate = new Date();
      const dueDate = new Date();
      dueDate.setDate(currentDate.getDate() + selectedDays);

      const formattedDueDate = dueDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      setDueDate(formattedDueDate);
    }
  };

  const updateInvoiceHandler = async (e) => {
    
    e.preventDefault();
    try {
      await updateInvoice(invoiceId, {noOfDueDates: numberOfDays, dueDate: dueDate})
        setInvoiceData((prevInvoiceData) => ({
          ...prevInvoiceData,
          invoice: {
            ...prevInvoiceData.invoice,
            noOfDueDates: numberOfDays,
            dueDate: dueDate,
          },
        }));
      console.log("Successfully created!!")
      appendAlert("Invoice updated Successfully.", "success");
    } catch (error) {
      console.log("Error Occurred!!")
      appendAlert("Please try again later.", "danger");
    }
  };

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  const handleNoteOnChange = (e) =>{
    setNote(e.target.value);
  }


  const unitOnChangeHandle = (e) => {
    resetStates();
    const selectedValue = e.target.value;
    setSelectedUnit(selectedValue);
    setDisabledFixedPriceField(selectedValue !== "fast-pris");

    if (selectedValue === "Timmar" || selectedValue === "st") {
      setDisabledBgColor("form-control disabled-field");
      setDisabledQuantityAndPrice(false);
      setQtyAndPriceBgClr("");
    } else if (selectedValue === "fast-pris") {
      setDisabledBgColor("form-control");
      setDisabledQuantityAndPrice(true);
      setQtyAndPriceBgClr("#dddddd");
    }
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      unit: selectedValue,
    }));
  };

  const onChangeHoursHandle = (e) => {
    let value = parseInt(e.target.value, 10);
    if (value < 0 || isNaN(value)) {
      setHoursWorked("");
    } else {
      setHoursWorked(value);
    }
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, quantity: value }));
  };

  const onChangeHourlyPriceHandle = (e) => {
    let value = parseInt(e.target.value);
    if (value < 0 || isNaN(value)) {
      setHourlyPrice("");
    } else {
      setHourlyPrice(value);
    }
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, price: value }));
  };

  const onChangeFixedPriceHandle = (e) => {
    setFixedPriceValue(e.target.value);
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      fixedPrice: e.target.value,
    }));
  };

  useEffect(() => {
    let total = 0;
    if (selectedUnit === "fast-pris") {
      setTotalAmount(parseFloat(fixedPriceValue) || 0);
      total = parseFloat(fixedPriceValue) || 0;
    } else {
      if (hoursWorked !== "" && hourlyPrice !== "") {
        total = hoursWorked * hourlyPrice;
        setTotalAmount(total);
      } else {
        setTotalAmount(0);
      }
    }
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, totalAmount: total }));
  }, [selectedUnit, hoursWorked, hourlyPrice, fixedPriceValue]);

  const onChangeTextAreaHandle = (e) => {
    setTextAreaValue(e.target.value);
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      workDescription: e.target.value,
    }));
  };

  const handleEditInvoiceAmount = (invoiceId) => {
    const selectedInvoice = invoiceData?.invoiceAmount.find(
      (item) => item._id === invoiceId
    );
    console.log(selectedInvoice);
    setSelectedInvoiceAmountId(invoiceId);
    if(selectedInvoice.unit === "timmar" || selectedInvoice.unit === "st"){
      setDisabledBgColor("form-control disabled-field");
      setDisabledQuantityAndPrice(false);
      setQtyAndPriceBgClr("");
      
      setInvoiceDetails({
        unit: selectedInvoice.unit || "timmar",
        quantity: selectedInvoice.quantity || "-",
        fixedPrice: null,
        price: selectedInvoice.price || "-",
        workDescription: selectedInvoice.workDescription || "-",
        totalAmount: selectedInvoice.totalAmount || "-",
      })
      setSelectedUnit(selectedInvoice.unit);
      setFixedPriceValue("");
      setHoursWorked(selectedInvoice.quantity);
      setHourlyPrice(selectedInvoice.price);
      setTotalAmount(selectedInvoice.totalAmount);
      setTextAreaValue(selectedInvoice.workDescription);
    }
    if(selectedInvoice.unit === "fast-pris"){
      setDisabledBgColor("form-control");
      setDisabledQuantityAndPrice(true);
      setQtyAndPriceBgClr("#dddddd");

      setInvoiceDetails({
        unit: selectedInvoice.unit,
        quantity: null,
        fixedPrice: selectedInvoice.fixedPrice,
        price: null,
        workDescription: selectedInvoice.workDescription,
        totalAmount: selectedInvoice.totalAmount,
      })
      setSelectedUnit(selectedInvoice.unit);
      setHoursWorked("");
      setHourlyPrice("");
      setFixedPriceValue(selectedInvoice.fixedPrice);
      setTotalAmount(selectedInvoice.totalAmount);
      setTextAreaValue(selectedInvoice.workDescription);
    }
    setShowModal(true);
  };

  const updateAmount = async (e) => {
    e.preventDefault();
    try {
      let updatedInvAmount;
      if (invoiceDetails.unit === "timmar" || invoiceDetails.unit === "st") {
        updatedInvAmount = await updateInvoiceAmount(selectedInvoiceAmountId, {
          invoiceId: invoiceDetails.invoiceId,
          unit: invoiceDetails.unit,
          quantity: invoiceDetails.quantity,
          price: invoiceDetails.price,
          workDescription: invoiceDetails.workDescription,
        });
      } else if (invoiceDetails.unit === "fast-pris") {
        updatedInvAmount = await updateInvoiceAmount(selectedInvoiceAmountId, {
          invoiceId: invoiceDetails.invoiceId,
          unit: invoiceDetails.unit,
          fixedPrice: invoiceDetails.fixedPrice,
          workDescription: invoiceDetails.workDescription,
        });
      }
  
      setInvoiceData((prevInvoiceData) => {
        const updatedInvoiceAmounts = prevInvoiceData.invoiceAmount.map((item) =>
          item._id === selectedInvoiceAmountId ? updatedInvAmount : item
        );
  
        const updatedInvoiceData = {
          ...prevInvoiceData,
          invoiceAmount: updatedInvoiceAmounts,
        };
  
        // Recalculate totalAmount
        const totalAmount = updatedInvoiceData.invoiceAmount.reduce(
          (acc, item) => acc + parseFloat(item.totalAmount || 0),
          0
        );
  
        updatedInvoiceData.invoice.totalAmount = totalAmount.toFixed(2);
  
        return updatedInvoiceData;
      });
  
      setShowModal(false);
      console.log("Updated Successfully");
      appendAlert("Invoice Amount updated Successfully.", "success");
    } catch (error) {
      appendAlert("Please try again later.", "danger");
      console.log("Error occurred!! please try again later");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDeleteInvoiceAmount = async (id) => {
    try {
      const shouldDelete = window.confirm('Are you sure you want to delete?');
      if (shouldDelete) {
        const deletionResult = await deleteInvoiceAmount(id);
        if (deletionResult) {
          setInvoiceData((prevInvoiceData) => {
            const updatedInvoiceAmount = prevInvoiceData.invoiceAmount.filter(
              (invoiceAmount) => invoiceAmount._id !== id
            );
  
            const updatedInvoiceData = {
              ...prevInvoiceData,
              invoiceAmount: updatedInvoiceAmount,
            };
  
            // Recalculate totalAmount
            const totalAmount = updatedInvoiceData.invoiceAmount.reduce(
              (acc, item) => acc + parseFloat(item.totalAmount || 0),
              0
            );
  
            updatedInvoiceData.invoice.totalAmount = totalAmount.toFixed(2);
  
            console.log("Invoice amount deleted successfully");
            appendAlert("Invoice Amount deleted Successfully.", "success");
  
            return updatedInvoiceData;
          });
        } else {
          console.error("Deletion was not successful", deletionResult.error);
          appendAlert("Please try again later.", "danger");
        }
      }
    } catch (error) {
      appendAlert("Please try again later.", "danger");
      console.log("Error occurred!! please try again later");
    }
  };

  const addNote = async (e) =>{
    e.preventDefault();
    setDisabledBtn(true);
    try {
      const response = await fetch(`${apiUrl}/api/invoice/addnote/${invoiceId}`,{
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
        },
        body: JSON.stringify({note}),
      })
      if(!response.ok){
        console.log('Error while adding note')
        setDisabledBtn(false);
        throw new Error(`Error while adding note`);
      }
      const data = await response.json();
      appendAlert("Anteckningen har lagts till.", "success");
      console.log(data);
      setDisabledBtn(false);
    } catch (error) {
      appendAlert("Det gick inte att lägga till anteckning", "danger");
      setDisabledBtn(false);
      console.error("Error while adding note", error);
    }
  }
  
  
  useEffect(() => {
    console.log("Updated Invoice Details:", invoiceDetails);
  }, [invoiceDetails]);
  
  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      {loading && <Spinner/>}
      {!loading && <><div className="heading-div fl-invoice-section-heading"
        style={{ width: "100%", marginLeft: "70px" }} >
        <h5 className="valuta-heading fl-create-client-heading">Faktura</h5>
      </div>
      <form onSubmit={updateInvoiceHandler} className="row invoice-create-form" style={{ width: "80%" }}>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="select-client-id" className="form-label form-lbl">
              Lägg en kund till fakturan
            </label>
            <div className="">
              <select
                className="fl-invoice-dropdown custom-field-box"
                name="select-client"
                id="select-client-id"
                disabled
              >
                <option className="invoice-item" value={invoiceData?.invoice?.clientName}>
                  {invoiceData?.invoice?.clientName}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="no-of-due-date-id" className="form-label form-lbl">
              Antal förfallodagar
            </label>
            <div className="">
              <select
                className="form-select custom-field-box"
                name="no-of-due-date"
                id="no-of-due-date-id"
                value={numberOfDays}
                onChange={handleNumberOfDaysChange}
                
              >
                <option value={invoiceData?.invoice?.noOfDueDates} defaultValue="Select number of days">
                  {invoiceData?.invoice?.noOfDueDates}
                </option>
                <option value="1" className="due-date-item">
                  1
                </option>
                <option value="3" className="due-date-item">
                  3
                </option>
                <option value="10" className="due-date-item">
                  10
                </option>
                <option value="20" className="due-date-item">
                  20
                </option>
                <option value="30" className="due-date-item">
                  30
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label form-lbl">
              Förfallodatum
            </label>
            <input
              type="text"
              className="form-control custom-field-box"
              id="exampleInputEmail1"
              value={dueDate}
              readOnly
              
            />
          </div>
        </div>
      <div className="create-invoice-btn-div">
        <button type="submit" className="create-invoice-btn">
          Spara kund och fortsätt
        </button>
      </div>
      </form>

      <hr style={{ width: "100%" }} />
      <div className="row invoice-row-container" style={{ width: "80%" }}>
        <div className="col invoice-col">
          <h6 className="bold-txt">Benämning</h6>
          {invoiceData?.invoiceAmount?.map((data, index) => (
            <div className='edit-invoice-amount' key={index}>
              <h6>{data.workDescription}</h6>
            </div>
          ))}
        </div>
        <div className="col invoice-col">
          <h6 className="bold-txt">Antal</h6>
          {invoiceData?.invoiceAmount?.map((data, index) => (
            <div className='edit-invoice-amount' key={index}>
              <h6>{data.quantity}</h6>
            </div>
          ))}
        </div>
        <div className="col invoice-col">
          <h6 className="bold-txt">Enhet</h6>
          {invoiceData?.invoiceAmount?.map((data, index) => (
            <div className='edit-invoice-amount' key={index}>
              <h6>{data.unit}</h6>
            </div>
          ))}
        </div>
        <div className="col invoice-col">
          <h6 className="bold-txt">Á-pris</h6>
          {invoiceData?.invoiceAmount?.map((data, index) => (
            <div className='edit-invoice-amount' key={index}>
              <h6>{data.price}</h6>
            </div>
          ))}
        </div>
        <div className="col invoice-col">
          <h6 className="bold-txt">Belopp</h6>
          {invoiceData?.invoiceAmount?.map((data, index) => (
            <div className='edit-invoice-amount' key={index}>
              <h6>{data.totalAmount}</h6>
            </div>
          ))}
        </div>
        <div className="col invoice-col">
          <h6 className="bold-txt">Redigera/Radera</h6>
          {invoiceData?.invoiceAmount?.map((data, index) => (
            <div className='edit-invoice-amount' key={index}>
            <Link onClick={() => handleEditInvoiceAmount(data._id)} to={`#`}  className="editClientBtn" >
            Redigera
            </Link>|
            <Link disabled style={{color: 'red'}} onClick={() => handleDeleteInvoiceAmount(data._id)} to={"#"}>Radera</Link>
            {/* <Link style={{color: '#c55a5ad9'}} to={"#"}>Radera</Link> */}

          </div>
          ))}
        </div>
      </div>
      {/* <form onSubmit={addNote} style={{ width: "80%", marginTop: "10px", textAlign: 'left' }}>
        <div>
          <label className="mb-1" htmlFor="addNote" style={{fontWeight: 'bold', display: "block"}}>Lägg till en notering på din faktura</label>
          <textarea onChange={handleNoteOnChange} value={note} style={{padding: '10px', borderRadius: '12px', display: "block", width: '50%'}} name="addNote" id="addNote" rows={4} className="mb-2"></textarea>

        </div>
        <button style={{width: '110px'}} type="submit" className="create-invoice-btn">Tillägga</button>
      </form> */}
        <FreelancerCreateInvoice setFirstInvoice={setFirstInvoice} invId={invoiceId} setInvoiceData={setInvoiceData} invoiceData={invoiceData} optionselect={optionselect} setSentStatus={setSentStatus} sentStatus={sentStatus} firstInvoice = {firstInvoice}/>
        {showModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header popup-blue-header">
                <h6 className="modal-title fs-5">Lägg en kund till fakturan</h6>
                <button
                  type="button"
                  className="btn-close popup-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={updateAmount}>
                <div className="modal-body inv-popup-body">
                  <div className="row">
                    <div className="col">
                      <label
                        htmlFor="inv-units"
                        className="form-label form-lbl"
                      >
                        Enhet
                      </label>
                      <select
                        value={selectedUnit}
                        className="form-select"
                        id="inv-units"
                        onChange={unitOnChangeHandle}
                      >
                        <option value="Timmar">Timmar</option>
                        <option value="st">St.</option>
                        <option value="fast-pris">Fast Pris</option>
                      </select>
                      <label
                        htmlFor="inv-quantity"
                        className="form-label form-lbl mt-2"
                      >
                        Antal
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="inv-quantity"
                        placeholder="0-9"
                        required
                        value={hoursWorked}
                        onChange={onChangeHoursHandle}
                        disabled={disabledQuantityAndPrice}
                        style={{ backgroundColor: qtyAndPriceBgClr }}
                      />
                    </div>

                    <div className="col">
                      <label
                        htmlFor="inv-fixed-price"
                        className="form-label form-lbl"
                      >
                        Fast Pris
                      </label>
                      <input
                        type="number"
                        value={fixedPriceValue}
                        className={disabledBgColor}
                        id="inv-fixed-price"
                        disabled={disabledFixedPriceField}
                        onChange={onChangeFixedPriceHandle}
                        required
                      />
                      <label
                        htmlFor="inv-price"
                        className="form-label form-lbl mt-2"
                      >
                        Pris
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="inv-price"
                        required
                        value={hourlyPrice}
                        onChange={onChangeHourlyPriceHandle}
                        disabled={disabledQuantityAndPrice}
                        style={{ backgroundColor: qtyAndPriceBgClr }}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="work-description"
                        className="form-label form-lbl mt-2"
                      >
                        Benämning
                      </label>
                      <textarea
                        value={textAreaValue}
                        onChange={onChangeTextAreaHandle}
                        className="form-control"
                        id="work-description"
                        rows="3"
                        required
                      ></textarea>
                    </div>
                    <h5 className="inv-total-amount">{`${totalAmount} EUR`}</h5>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary inv-save-btn"
                  >
                    Spara ändringar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary inv-close-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Stäng fönstret
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}</>}
    </Layout>
  )
}

export default FreelancerEditInvoice
