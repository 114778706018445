import React, {useContext, useEffect} from 'react'
import userContext from "../../context/UserContext";
function PrivateUserform({onChange, values, setPrivateUserInfo, onChangeMobileNo, onChangeTelNo, onChangeWhatsappNumber}) {
  const context = useContext(userContext);
  const {getUserProfileData, userProfile, user, firstName, lastName, getUser} = context;

  useEffect(()=>{
    getUserProfileData()
    getUser();
  }, []);

  useEffect(() => {
    // Log userProfile when it changes
    console.log(userProfile);
    setPrivateUserInfo({
      pvtFname: userProfile.privateUserInfo?.pvtFname || firstName,
      pvtLname: userProfile.privateUserInfo?.pvtLname || lastName,
      pvtUserEmail: userProfile.privateUserInfo?.pvtUserEmail || user?.email || "",
      pvtTel: userProfile.privateUserInfo?.pvtTel || "",
      pvtMobNo: userProfile.privateUserInfo?.pvtMobNo || user?.phoneNo,
      pvtSkypeName: userProfile.privateUserInfo?.pvtSkypeName || "",
    });
  }, [userProfile]);
  return (
    <>
    <div className="row">
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="pvtFname" className="label form-label">
                    Förnamn<span style={{color: 'red'}}>*</span>
              </label>
              <input type="text" value={values.pvtFname} className="form-input form-control pvt-input-field" id="pvtFname" onChange={onChange} required/>
            </div>

            <div className="">
              <label htmlFor="pvtLname" className="label form-label">
                    Efternamn<span style={{color: 'red'}}>*</span>
              </label>
              <input type="text" value={values.pvtLname} className="form-input form-control pvt-input-field" id="pvtLname" onChange={onChange} required/>
            </div>
            <div className="">
              <label htmlFor="pvtUserEmail" className="label form-label">
                     E-post
              </label>
              <input disabled type="email" value={values.pvtUserEmail} className="form-input form-control pvt-input-field" id="pvtUserEmail" onChange={onChange}/>
            </div>
            <div className="">
              <label htmlFor="pvtTel" className=" label form-label">
              Telefon nummer (Telefon nummer Måste även inkludera landskoden, t.ex.+46)
              </label>
              <input type="text" name='pvtTel' value={values.pvtTel} className="form-input form-control pvt-input-field" id="pvtTel" onChange={onChangeTelNo}/>
            </div>
            <div className="">
              <label htmlFor="pvtMobNo" className=" label form-label">
              Mobil nummer (Mobil nummer Måste även inkludera landskoden, t.ex.+46) <span style={{color: 'red'}}>*</span>
              </label>
              <input type="text" value={values.pvtMobNo} name='pvtMobNo' className="form-input form-control pvt-input-field pvt-input-field" id="pvtMobNo" onChange={onChangeMobileNo} required/>
            </div>
            <div className="">
              <label htmlFor="pvtSkypeName" className=" label form-label">
                    WhatsApp nummer (Måste även inkludera landskoden, t.ex.+46):
              </label>
              <input type="text" value={values.pvtSkypeName} className="form-input form-control pvt-input-field" id="pvtSkypeName" onChange={onChangeWhatsappNumber}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivateUserform
