import React, { useState, useEffect, useContext } from "react";
import Layout from "../../common/Layout";
import "../../styles/FreelancerInvoiceSection.css";
import FreelancerCreateInvoice from "./FreelancerCreateInvoice";
import userContext from "../../context/UserContext";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";

function FreelancerInvoiceSection() {
  const navigate = useNavigate()
  const context = useContext(userContext);
  const {createInvoice} = context;
  const [allInvoiceDetails, setAllInvoiceDetails] = useState([]);
  const [invoiceView, setInvoiceView] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [clientName, setClientName] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ id: "", name: "" });
  const [disabledFields, setDisabledFields] = useState(false);
  const [loading, setLoading] = useState(true);
  let invSecOption = true;
  const apiUrl = 'https://globalworker-backend.vercel.app';
  useEffect(() => {
    const getClientData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/client/fetchallclients`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const clientData = await response.json();
        if(clientData.clients && clientData.clients.length !== 0){
          const privateClientFirstNames = clientData.clients.filter((client) => client.clientOption === "private")
            .map((client) => ({
              id: client._id,
              name: `${client.privateClient.pvtFirstName} ${client.privateClient.pvtLastName}`,
            }));
            const organizationClientFirstNames = clientData.clients.filter((client) => client.clientOption === "organization")
              .map((client) => ({
                id: client._id,
                name: `${client.organizationClient.orgFirstName} ${client.organizationClient.orgLastName}`,
              }));
    
            setClientName([
              ...privateClientFirstNames,
              ...organizationClientFirstNames,
            ]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error while getting Client info:", error);
      }
    };
    getClientData();
  }, []);

  const handleNumberOfDaysChange = (e) => {
    const selectedDays = parseInt(e.target.value, 10);
    setNumberOfDays(selectedDays);
    if (!isNaN(selectedDays)) {
      const currentDate = new Date();
      const dueDate = new Date();
      dueDate.setDate(currentDate.getDate() + selectedDays);

      const formattedDueDate = dueDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      setDueDate(formattedDueDate);
    }
  };

  const handleClientSelect = (e) => {
    const selectedClientId = e.target.value;
    const selectedClientName = clientName.find(client => client.id === selectedClientId).name;
    setSelectedClient({ id: selectedClientId, name: selectedClientName });
    console.log('Selected Client ID:', selectedClientId);
  };

  const handleInvoiceDetails = (invoiceDetails) => {
    setAllInvoiceDetails((prevDetails) => [...prevDetails, { ...invoiceDetails, clientId: selectedClient.id }]);
    console.log('Received Invoice Details:', invoiceDetails);
  };

  const createInvoiceHandler = async (e) => {
    
    e.preventDefault();
    try {
      const invoiceID = await createInvoice({clientId: selectedClient.id, noOfDueDates: numberOfDays, dueDate: dueDate})
      setInvoiceView('invoice');
      setDisabledFields(true);
      console.log("Successfully created!!")
      appendAlert("Invoice Created Successfully.", "success");
      navigate(`/freelancer-edit-invoice/${invoiceID}`)
    } catch (error) {
      console.log("Error Occurred!!")
      appendAlert("Please try again later.", "danger");
    }
  };

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      {loading && <Spinner/>}
      {!loading && <><div
        className="heading-div fl-invoice-section-heading"
        style={{ width: "100%", marginLeft: "70px" }}
      >
        <h5 className="valuta-heading fl-create-client-heading">Skapa Ny Faktura</h5>
      </div>
      <form onSubmit={createInvoiceHandler} className="row invoice-create-form" style={{ width: "80%" }}>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="select-client-id" className="form-label form-lbl">
            Lägg till en kund på fakturan
            </label>
            <div className="">
              <select
                className="fl-invoice-dropdown custom-field-box"
                name="select-client"
                id="select-client-id"
                onChange={handleClientSelect}
                disabled={disabledFields}
                required
              >
                <option className="invoice-item" value="">
                Välj klient
                </option>
                {clientName.map((client, index) => (
                  <option key={index} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="no-of-due-date-id" className="form-label form-lbl">
              Antal förfallodagar
            </label>
            <div className="">
              <select
                className="form-select custom-field-box"
                name="no-of-due-date"
                id="no-of-due-date-id"
                value={numberOfDays}
                onChange={handleNumberOfDaysChange}
                disabled={disabledFields}
                required
              >
                <option value="" defaultValue="Välj antal dagar">
                Välj antal dagar
                </option>
                <option value="1" className="due-date-item">
                  1
                </option>
                <option value="3" className="due-date-item">
                  3
                </option>
                <option value="10" className="due-date-item">
                  10
                </option>
                <option value="20" className="due-date-item">
                  20
                </option>
                <option value="30" className="due-date-item">
                  30
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label form-lbl">
              Förfallodatum
            </label>
            <input
              type="text"
              className="form-control custom-field-box"
              id="exampleInputEmail1"
              value={dueDate}
              readOnly
              disabled={disabledFields}
            />
          </div>
        </div>
      <div className="create-invoice-btn-div">
        <button type="submit" className="create-invoice-btn" disabled={disabledFields}>
          Spara kund och fortsätt
        </button>
      </div>
      </form>

      <hr style={{ width: "100%" }} />
      <div className="row invoice-row-container" style={{ width: "80%" }}>
        <div className="col">
          <h6 className="bold-txt">Benämning</h6>
          {allInvoiceDetails.map((details, index) => (
            <h6 key={index}>{details.workDescription}</h6>
          ))}
        </div>
        <div className="col">
          <h6 className="bold-txt">Antal</h6>
          {allInvoiceDetails.map((details, index) => (
            <h6 key={index}>{details.quantity}</h6>
          ))}
        </div>
        <div className="col">
          <h6 className="bold-txt">Enhet</h6>
          {allInvoiceDetails.map((details, index) => (
            <h6 key={index}>{details.unit}</h6>
          ))}
        </div>
        <div className="col">
          <h6 className="bold-txt">Á-pris</h6>
          {allInvoiceDetails.map((details, index) => (
            <h6 key={index}>{details.price}</h6>
          ))}
        </div>
        <div className="col">
          <h6 className="bold-txt">Belopp</h6>
          {allInvoiceDetails.map((details, index) => (
            <h6 key={index}>{details.totalAmount}</h6>
          ))}
        </div>
      </div>
      
      {invoiceView === "invoice" && (
        <FreelancerCreateInvoice onInvoiceDetails={handleInvoiceDetails} invSecOption={invSecOption} allInvoiceDetails={allInvoiceDetails}/>
      )}</>}
    </Layout>
  );
}

export default FreelancerInvoiceSection;
