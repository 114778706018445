import React, { useState } from 'react';
import '../../common/styles/Signin.css';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../freelancer/components/Spinner';

const FreelancerSignupForm = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showPassCheck, setShowPassCheck] = useState(false)
  const apiUrl = 'https://globalworker-backend.vercel.app';
  
  const handleNameOnChange = (e) =>{
    setName(e.target.value);
  }

  const handleLastNameOnChange = (e) =>{
    setLastName(e.target.value);
  }

  const handlePhoneOnChange = (e) =>{
    let inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen
    
    if (!sanitizedValue) {
      sanitizedValue = ''; // Change this to the desired default country code
  } else {
      // Check if the input doesn't start with a plus symbol
      if (!sanitizedValue.startsWith('+')) {
          // Assuming country code is '46', replace it with the actual country code
          sanitizedValue = '+' + sanitizedValue;
      }
  }

    // Check if there's a plus symbol not at the start and remove it
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    } else {
      sanitizedValue = sanitizedValue.replace(/\+/g, '');
    }

    setPhoneNo(sanitizedValue);
  }

  const handleSignup = async (e) =>{
    e.preventDefault();
    setDisableBtn(true);
    const fname = `${name} ${lastName}`;
    setLoading(true)
    try {
      if(password !== confirmPassword || confirmPassword !== password){
        appendAlert("Password do not match.", "danger");
        setLoading(false)
      }
      else{
        const response = await fetch(`${apiUrl}/api/auth/createuser`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: fname, phoneNo, email, password, confirmPassword }),
        })
        const data = await response.json();
        if(response.ok){
          localStorage.setItem("token", data.authtoken);
          setDisableBtn(false)
          navigate("/");
          console.log("User created successful.");
          setLoading(false)
          console.log(data.authtoken)
        }
        else{
          appendAlert("This email already exists.", "danger");
          console.log("Signup failed");
          setLoading(false)
          setDisableBtn(false)
        }
      }
    } catch (error) {
      appendAlert("Please try again later.", "danger");
      setDisableBtn(false)
      setLoading(false)
    }
  }

  const showPassword = () =>{
    if(!showPassCheck){
      setShowPassCheck(true)
    }
    else if(showPassCheck){
      setShowPassCheck(false)
    }
}

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
  return (
    <>
    <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
    <form onSubmit={handleSignup} style={{width: '100%'}}>
    <div className="sign-in-up-btn-div">
      <h3 id="login-heading">Registrera</h3>
    </div>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Svenska (Sverige)
            </button>
            <ul className="dropdown-menu" aria-labelledby="languageDropdown">
              <li>
                <a className="dropdown-item" href="#" onClick={() => changeLanguage('Svenska (Sverige)')}>
                  Svenska (Sverige)
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => changeLanguage('English')}>
                  English
                </a>
              </li>
              {/* Add more language options as needed */}
            </ul>
          </div>
          <hr id="divider" className='mt-4'/>
          <label className="form-lebels" htmlFor="signup-name">
           Förnamn <span style={{color: 'red'}}>*</span>
          </label>
          <input
            type="text"
            id="signup-name"
            className="form-control mb-3"
            value={name}
            onChange={handleNameOnChange}
            required
            minLength={3}
          />
          <label className="form-lebels" htmlFor="signup-Lname">
            Efternamn <span style={{color: 'red'}}>*</span>
          </label>
          <input
            type="text"
            id="signup-Lname"
            className="form-control mb-3"
            value={lastName}
            onChange={handleLastNameOnChange}
            required
            minLength={3}
          />
            <label className="form-lebels" htmlFor="signup-email">
              Mobil nummer (Mobil nummer Måste även inkludera landskoden, t.ex. +46) <span style={{color: 'red'}}>*</span>
            </label>
            <input
              type="text"
              id="signup-phoneNo"
              className="form-control mb-3"
              placeholder="+46"
              value={phoneNo}
              onChange={handlePhoneOnChange}
              required
            />
          <label className="form-lebels" htmlFor="signup-email">
            E-post <span style={{color: 'red'}}>*</span>
          </label>
          <input
            type="email"
            id="signup-email"
            className="form-control mb-3"
            placeholder="example123@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="form-lebels" htmlFor="signup-Password">
            Lösenord <span style={{color: 'red'}}>*</span>
          </label>
          <input
            type={showPassCheck ? 'text' : "password"}
            id="signup-Password"
            className="form-control mb-3"
            placeholder="***********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label className="form-lebels" htmlFor="signup-confirm-password">
                Bekräfta lösenord <span style={{color: 'red'}}>*</span>
            </label>
            <input
                type={showPassCheck ? 'text' : "password"}
                id="signup-confirm-password"
                className="form-control mb-3"
                placeholder="***********"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
          <div className="main-check-container">
            <div className="form-check-container">
              <input type="checkbox" id="autoSizingCheck2" className="form-check-input-field mr-2" value={showPassCheck} onClick={showPassword} />
              <label style={{marginLeft: '6px'}} className="form-check-lab " htmlFor="autoSizingCheck2" >
                  Visa lösenord
              </label>
            </div>
          </div>
          <button type="submit" id="login-btn" disabled={disableBtn} className="btn btn-primary mt-3 mb-2">
            Registrera
          </button>
          <div className='regToLoginDiv'>
            <p>Har du redan ett konto?</p>
                <Link style={{marginLeft: '6px'}} to='/signin'>Logga in</Link>
          </div>
          <div className='mt-4'>
            {loading && <Spinner/>}
          </div>
    </form>
    </>
  );
};

export default FreelancerSignupForm;
// Add this function outside the component
function changeLanguage(language) {
    document.getElementById('languageDropdown').innerText = language;
  }
  