import React, { useEffect, useState, useContext } from 'react'
import Layout from '../../common/Layout'
import { Link } from 'react-router-dom';
import '../../styles/FreelancerInvoiceList.css';
import userContext from '../../context/UserContext';
function FreelancerInvoiceList() {
  const context = useContext(userContext);
  const {deleteInvoice, changeInvoiceBtnTxt, setChangeInvoiceBtnTxt} = context;
  const [invoiceData, setInvoiceData] = useState([]);
  const apiUrl = 'https://globalworker-backend.vercel.app';
  const url = window.location.origin;
  useEffect(()=>{
    const getInvoiceList = async () =>{
      try {
        const response = await fetch(`${apiUrl}/api/invoice/fetchinvoices`, {
          method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
        })
        if (!response.ok) {
          throw new Error(`Failed to fetch Invoices. Status: ${response.status}`);
        }
        const invoices = await response.json();
        setInvoiceData(invoices);
      } catch (error) {
        console.error('Error fetching client:', error);
        throw error;
      }
    }
    getInvoiceList();
  }, [])

  const handleDeleteInvoice = async (id) =>{
    try {
      const deleteConfirmation = window.confirm('Are you sure you want to delete?');
      if(deleteConfirmation){
        const response = await deleteInvoice(id);
        if(response){
          setInvoiceData((prevInvoiceData) =>
            prevInvoiceData.filter((invoice) => invoice._id !== id)
            );
          console.log("Invoice deleted successfully");
          appendAlert("Invoice deleted Successfully.", "success");
        }
        else{
          console.log("Error Occurred");
          appendAlert("Please try again later.", "danger");
        }
      }
    } catch (error) {
      console.log("Error Occurred");
      appendAlert("Please try again later.", "danger");
    }
  }

  useEffect(()=>{
    console.log(window.location.origin)
    if(window.location.href === `${url}/freelancer-view-invoices`){
      setChangeInvoiceBtnTxt('previewInvoice')
    }
    else if(window.location.href === `${url}/freelancer-view-invoices/edit`){
      setChangeInvoiceBtnTxt('editInvoice')
    }
    else if(window.location.href === `${url}/freelancer-view-invoices/salary-spec`){
      setChangeInvoiceBtnTxt('salarySpec')
    }
  }, [])

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div
        className="heading-div bank-info-heading-div"
        style={{ width: "100%", marginLeft: "70px" }}>
        <h5 className="valuta-heading bank-info-heading">{changeInvoiceBtnTxt === 'previewInvoice' && 'Förhandsgranska Faktura'} {changeInvoiceBtnTxt === 'editInvoice' && 'Ändra Faktura'} {changeInvoiceBtnTxt === 'salarySpec' && 'Förhandsgranska lönespecar'}</h5>
      </div>
      <div className="client-list-container">
        <div className="row client-list-row">
          <div className="col">
            <h6 className="bold-txt">Fakturanummer</h6>
            {invoiceData.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
              <h6>{data.invoiceNumber}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">Kund Namn</h6>
            {invoiceData.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
              <h6>{data.clientName}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">Belopp</h6>
            {invoiceData.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
              <h6>{data.totalAmount}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">Förfallodag</h6>
            {invoiceData.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
                <h6>{data.dueDate}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">{changeInvoiceBtnTxt === 'previewInvoice' && 'Förhandsgranska Faktura'} {changeInvoiceBtnTxt === 'editInvoice' && 'Ändra Faktura'} {changeInvoiceBtnTxt === 'salarySpec' && 'Förhandsgranska lönespecar'}</h6>
            {invoiceData.map((data, index) => (
              <div className='invoiceListRow' key={index}>
                {changeInvoiceBtnTxt === 'previewInvoice' && <Link style={{marginBottom: '0.5rem'}} to={`/generated-invoice/${data._id}`}>Förhandsgranska Faktura</Link>} {changeInvoiceBtnTxt === 'editInvoice' && <Link style={{marginBottom: '0.5rem'}} to={`/freelancer-edit-invoice/${data._id}`}  className="editClientBtn" >
                Ändra Faktura
                </Link>} {changeInvoiceBtnTxt === 'salarySpec' && <Link style={{marginBottom: '0.5rem'}} to={`/generated-salary-invoice/${data._id}`}  className="editClientBtn" >
                Förhandsgranska lönespecar
                </Link>}
                
                {/* |<Link onClick={()=>{handleDeleteInvoice(data._id)}} style={{color: 'red'}} to={"#"}>Radera</Link> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FreelancerInvoiceList
